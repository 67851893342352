import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUseEn from "./TermsOfUseEn";
import TermsOfUseCn from "./TermsOfUseCn";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Routes = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: <App />,
    },

    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfUseEn />,
    },
    {
      path: "/terms-of-service-cn",
      element: <TermsOfUseCn />,
    },
  ]);
  return routes;
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
