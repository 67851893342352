import React from "react";
import styled from "styled-components";

export default function TermsOfUseCn() {
  return (
    <Wrapper>
      <div className="container">
        <h1>服务条款</h1>
        <p>最后更新日期：2023 6月</p>
        <p>
          1) 简介：本服务条款是用户与 Richlink Technologies Inc.
          之间关于使用社交媒体应用程序（“Vhot”）的法律协议。通过访问、安装或使用
          Vhot
          应用程序，您同意受本服务条款的约束。如果您不同意本服务条款，请勿安装或使用该应用程序。
          <br />
          <br />
          2) 许可授予：Richlink Technologies Inc.
          授予您不可转让、非独占的许可，允许您在您拥有或控制的单个移动设备上下载、安装和使用
          Vhot App，仅供您个人使用。
          <br />
          <br />
          3)
          使用限制：您不得复制、分发、出售或以其他方式转让应用程序或其任何部分。您不得修改、逆向工程、反编译或创建基于应用程序或其任何部分的衍生作品。您不得将
          Vhot App 用于任何非法或未经授权的目的。
          <br />
          <br />
          4) 知识产权：Vhot
          App及与之相关的所有知识产权，包括但不限于商标、专利、著作权等，均归
          Richlink Technologies Inc.所有，您不因此获得该App的任何所有权使用它。
          <br />
          <br />
          5) 用户内容：您对您通过 Vhot App
          发布、上传或以其他方式提供的任何内容、信息或数据（“用户内容”）承担全部责任。您声明并保证您拥有或拥有使用和授权
          Richlink Technologies Inc. 使用您通过 Vhot App
          发布、上传或以其他方式提供的所有用户内容的必要权利。
          <br />
          <br />
          6) 使用限制：
          <br />
          <br />
          以下是服务中禁止的内容种类的部分列表。您不得发布、上传、展示或以其他方式提供以下内容：
          <br />
          <br />
          <ul>
            <li>
              描绘、招揽、美化或鼓励虐待儿童的图像，包括裸露、性化未成年人或与未成年人发生性行为
            </li>
            <li> 描述或提供有关如何进行危险活动的指导细节 </li>
            <li> 描绘、提倡、规范或美化自杀或自残 </li>
            <li>
              {" "}
              描述、宣传或美化性诱惑，包括提供或要求性伴侣、性聊天或图像、性服务、优质性内容或性爱视频{" "}
            </li>
            <li>
              {" "}
              侮辱另一个人，或根据智力、外表、性格特征或卫生等属性贬低一个人{" "}
            </li>
            <li>
              {" "}
              声称具有受保护属性的个人或团体在身体、精神或道德上低人一等，或称他们为罪犯、动物、无生命物体或其他非人类实体{" "}
            </li>
            <li> 鼓励他人实施暴力的有条件或有抱负的陈述 </li>
            <li>
              分享有关如何人为增加观看次数、喜欢、关注者、分享或评论的说明{" "}
            </li>
            <li>宣扬人身伤害行为，例如殴打或绑架</li>
            <li> 描绘以下内容的人类内容：暴力或血腥死亡或事故</li>
            <li>描述动物的内容：动物的屠宰或其他非自然死亡 </li>
            <li>违反或侵犯他人版权、商标或其他知识产权的内容</li>
            <li>
              尝试以未经授权的方式访问 Vhot 平台，不要创建 Vhot
              平台的虚假或误导性版本
            </li>
          </ul>
          有关详细信息，请查看我们的社区准则。如果您发现不当内容违反了我们的社区准则或有任何其他您想提出的问题，您可以向我们发送报告。
          <br />
          <br /> Vhot 采取合理措施迅速删除我们从我们的服务中发现的任何侵权材料。
          Vhot
          的政策是，在适当的情况下并自行决定禁用或终止屡次侵犯他人版权或知识产权的服务用户的帐户。
          <br />
          <br />
          7) 免责声明： Vhot
          App“按原样”提供，不提供任何明示或暗示的保证，包括但不限于对适销性和特定用途适用性的暗示保证。
          Richlink Technologies Inc. 不保证 Vhot App
          将满足您的要求或其运行不会中断或没有错误。
          <br />
          <br />
          8)责任限制： Richlink Technologies Inc.不对因使用Vhot
          App而产生的任何损害承担责任，包括但不限于利润损失或数据损失。
          <br />
          <br />
          9)终止： Richlink Technologies Inc.
          可随时出于任何原因终止本服务条款，无论是否通知您。终止后，您必须立即停止使用该应用程序并将其从您的移动设备中删除。
          <br />
          <br />
          10)适用法律：本服务条款应受 Richlink Technologies Inc.
          所在地的法律管辖并据其解释。
          <br />
          <br />
          11)完整协议：本服务条款构成您与 Richlink Technologies Inc.
          之间关于使用 Vhot App
          的完整协议，并取代所有先前的口头或书面协议、谅解和协商。
        </p>
        <p>接受：访问、安装或使用 Vhot App，即表示您接受本服务条款和条件。</p>
        <p>如果您对本服务条款有任何疑问，请通过 info@richlink.ca 与我们联系.</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  padding: 30px;

  h1 {
    font-size: 26px;
    color: #fff;
    padding-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    color: #fff;
    padding: 16px 0px;
  }

  p {
    color: #fff;
    padding: 10px 0px;
  }
`;
