import React from "react";

import styled from "styled-components";

const DownloadApp = () => {
  return (
    <Wrapper className="d-flex flex-row justify-content-center align-items-center">
      <a
        href="https://apps.apple.com/ca/app/vhot/id1663976918"
        style={{
          textDecoration: "none",
        }}
      >
        <div className="store">
          <img width={26} src="apple.svg" alt="img" />
          <p
            style={{
              color: "#fff",
            }}
            className="mx-3 mt-1"
          >
            App Store
          </p>
        </div>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=ca.Yexc.Vhot"
        style={{
          textDecoration: "none",
        }}
      >
        <div className="store">
          <img width={26} src="google.svg" alt="img" />
          <p
            style={{
              color: "#fff",
            }}
            className="mx-3 mt-1"
          >
            Google Play
          </p>
        </div>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .store {
    border: 1px solid white;
    border-radius: 100px;
    padding: 10px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: row;
    jutsify-content: center;
    align-items: center;
  }
`;

export default DownloadApp;
