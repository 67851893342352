import React, { useEffect } from "react";

import "./App.css";

import styled from "styled-components";

import DownloadAppMobile from "./components/DownloadAppMoblie";
import Lottie from "lottie-web";
import useLang from "./hooks/useLang";

function Mobile() {
  const { lang, setLang, CN, EN } = useLang();

  useEffect(() => {
    const animation1 = Lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById("lottie1mobile"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/493bab13-d61f-4812-b103-e5ebeba2a40b/VN6jwVX7LF.json",
    });
    const animation2 = Lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById("lottie2mobile"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/5638f79b-e6fc-463d-b86e-cb10ecc1d821/TWmuK7vJ8e.json",
    });
    return () => {
      animation1.destroy();
      animation2.destroy();
    };
  }, []);

  return (
    <Wrapper>
      <div>
        <div className="text-center">
          <img src="logo.svg" className="mb-5" alt="img" />
          <div className="d-flex flex-row lang-wrapper justify-content-center align-items-center mb-5">
            <p onClick={() => setLang(CN)}>中文</p>
            <p className="mx-4">|</p>
            <p onClick={() => setLang(EN)}>English</p>
          </div>
          <DownloadAppMobile />
        </div>

        {/* section 1 */}
        <div className="s1 container p-4">
          <h2 className="text-center">
            {`${lang.s11}, ${lang.s12}, ${lang.s13}`}
          </h2>
          {/* <div className="w-100 p-5" id="lottie1mobile"></div> */}
        </div>

        {/* section 2 */}
        <div className="s2s">
          <img className="w-75 p-4" src="vtxt.png" alt="img" />
          <img className="w-100 p-5" src="part2.svg" alt="" />
          <div className="p-4">
            <h3>{lang.s21}</h3>
            <h2 className="my-4">{lang.s22}</h2>
            <p className="my-4">{lang.s23}</p>
          </div>
        </div>

        {/* section 3 */}
        <div className="container text-end w-100">
          <img className="video-text" src="video-text.svg" alt="img" />
        </div>
        <div className="s3">
          <div className="container">
            <div>
              <h3>
                {lang.s31}, {lang.s32}, {lang.s33}
              </h3>
              <p>{lang.s34}</p>
            </div>
          </div>
        </div>

        {/* section 4 */}
        <div className="s4 container">
          <h2 className="text-center">
            {lang.s41} <br /> {lang.s42} <br /> {lang.s43}
          </h2>

          <div className="d-flex w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="block">
              <img src="cards/3.png" alt="" />
              <p> {lang.s44} </p>
            </div>
            <div className="block">
              <img src="cards/5.png" alt="" />
              <p> {lang.s45} </p>
            </div>
            <div className="block">
              <img src="cards/4.png" alt="" />
              <p> {lang.s46} </p>
            </div>
          </div>
        </div>

        {/* section 5 */}
        <div className="s5 container">
          <h2 className="p-4"> {lang.s51} </h2>

          <div className="p-4">
            <h4
              style={{
                fontSize: "24px",
                fontWeight: 300,
              }}
            >
              {lang.s52}
              <span
                style={{
                  color: " #E6005A",
                }}
              >
                {" "}
                Vhot
              </span>
            </h4>
          </div>

          <div className="p-4">
            <div className="w-100" id="lottie2mobile"></div>
          </div>
        </div>

        {/* download app  */}
        <div className="d-flex flex-column justify-content-start align-items-start">
          <h3 className="mx-4 mt-5">{lang.s53}</h3>
          <div className="my-5">
            <DownloadAppMobile />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #2e0012;
  padding-top: 40px;
  padding-bottom: 60px;
  min-height: 100vh;
  color: #fff;

  h1 {
    font-size: 35px;
    line-height: 76px;
  }

  .store {
    border: 1px solid white;
    border-radius: 100px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    jutsify-content: center;
    align-items: center;
    margin: 0px 20px;
  }

  .s1 {
    margin-top: 60px;
    h1 {
      padding: 120px 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 99px;
    }
  }

  .s2 {
    .vhot-text {
      width: 60%;
    }
    padding: 0px 100px;
    h3 {
      font-size: 30px;
      font-weight: 300;
    }
    h2 {
      font-size: 36px;
      font-weight: 300;
      margin: 60px 0px;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      text-decoration: underline;
      :hover {
        cursor: pointer;
      }
    }
  }

  .video-text {
    width: 60%;
  }

  .s3 {
    background-image: url("./images/7.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 40px;
    padding-bottom: 80px;
    h3 {
      padding: 30px;
      font-size: 30px;
    }
    p {
      padding: 30px;
      font-size: 18px;
    }
  }

  .s4 {
    padding: 100px 0px;

    h1 {
      margin-bottom: 80px;
    }

    .block {
      margin: 60px;
      border-radius: 40px;
      width: 260px;
      background-color: #d9d9d9;
      text-align: center;
      img {
        width: 100%;
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 33px;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }
`;

export default Mobile;
