import React from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

export default function App() {
  return (
    <div>
      <div className="desktop-wrapper">
        <Desktop />
      </div>
      <div className="mobile-wrapper">
        <Mobile />
      </div>
    </div>
  );
}
