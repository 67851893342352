import React, { useEffect } from "react";

import "./App.css";

import styled from "styled-components";

import DownloadApp from "./components/DownloadApp";

import useLang from "./hooks/useLang";

import Lottie from "lottie-web";

function Desktop() {
  const { lang, setLang, CN, EN } = useLang();

  useEffect(() => {
    const animation1 = Lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById("lottie1"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/493bab13-d61f-4812-b103-e5ebeba2a40b/VN6jwVX7LF.json",
    });
    const animation2 = Lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById("lottie2"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "https://lottie.host/5638f79b-e6fc-463d-b86e-cb10ecc1d821/TWmuK7vJ8e.json",
    });
    return () => {
      animation1.destroy();
      animation2.destroy();
    };
  }, []);

  return (
    <Wrapper>
      <div>
        <div className="header container d-flex justify-content-between align-items-center">
          <img src="logo.svg" alt="img" data-aos="fade-down" />
          <div data-aos="fade-down">
            <DownloadApp />
          </div>

          <div className="d-flex flex-row lang-wrapper" data-aos="fade-down">
            <p onClick={() => setLang(CN)}>中文</p>
            <p className="mx-4">|</p>
            <p onClick={() => setLang(EN)}>English</p>
          </div>
        </div>

        {/* section 1 */}
        <div className="s1 container d-flex flex-row w-100">
          <h1 className="w-50 px-5" data-aos="fade-right">
            {lang.s11}, <br /> {lang.s12}, <br /> {lang.s13}
          </h1>

          <img className="w-50 px-5" src="gif1-min.gif" alt="" />
          {/* <div data-aos="fade-left" className="w-50 p-5" id="lottie1"></div> */}
        </div>

        {/* section 2 */}
        <div className="s2">
          <img className="vhot-text" src="vtxt.png" alt="img" />
          <div
            className="w-100 d-flex flex-column flex-md-row justify-content-around align-items-center text-center"
            style={{ margin: "120px 0px" }}
          >
            <img
              data-aos="fade-right"
              style={{ width: "30%", padding: "0px 50px" }}
              src="part2.svg"
              alt="img"
            />
            <div data-aos="fade-left" className="px-5" style={{ width: "60%" }}>
              <h1>{lang.s21}</h1>
              <h2>{lang.s22}</h2>
              <p>{lang.s23}</p>
            </div>
          </div>
        </div>

        {/* section 3 */}
        <div className="container text-end w-100">
          <img
            className="video-text"
            style={{
              width: "50px",
            }}
            src="video-text.svg"
            alt="img"
          />
        </div>
        <div className="s3">
          <div className="container">
            <div data-aos="fade-in">
              <h3>
                {lang.s31}, <br /> {lang.s32}, <br /> {lang.s33}
              </h3>
              <p>{lang.s34}</p>
            </div>
          </div>
        </div>

        {/* section 4 */}
        <div className="s4 container">
          <h1 className="text-center" data-aos="zoom-in">
            {lang.s41} <br /> {lang.s42} <br /> {lang.s43}
          </h1>

          <div className="d-flex w-100 d-flex flex-row justify-content-center align-items-center">
            <div className="block" data-aos="flip-left">
              <img src="cards/3.png" alt="" />
              <p>{lang.s44}</p>
            </div>
            <div className="block" data-aos="flip-right">
              <img src="cards/5.png" alt="" />
              <p>{lang.s45}</p>
            </div>
            <div className="block" data-aos="flip-left">
              <img src="cards/4.png" alt="" />
              <p>{lang.s46}</p>
            </div>
          </div>
        </div>

        {/* section 5 */}
        <div className="s5 container">
          <h1 className="p-4">{lang.s51}</h1>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center">
            <div className="w-50 p-4" data-aos="fade-right">
              <h4
                style={{
                  fontSize: "42px",
                  fontWeight: 300,
                }}
              >
                {lang.s52 + " "}
                <span
                  style={{
                    color: " #E6005A",
                  }}
                >
                  Vhot
                </span>
              </h4>
            </div>

            <div
              style={{
                width: "50%",
                padding: "20px",
              }}
              // className="p-5"
              data-aos="fade-left"
              id="lottie2"
            ></div>

            {/* <div className="w-50 p-5" data-aos="fade-left">
              <img src="gif2.gif" alt="" />
            </div> */}
          </div>
          {/* download app  */}
          <div className="d-flex flex-column justify-content-start align-items-start">
            <h3 className="mx-4 mt-5">{lang.s53}</h3>
            <div className="my-5">
              <DownloadApp />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #2e0012;
  padding-top: 60px;
  padding-bottom: 120px;
  min-height: 100vh;
  color: #fff;

  .lang-wrapper {
    :hover {
      cursor: pointer !important;
    }
  }

  h1 {
    font-size: 65px;
    line-height: 76px;
  }

  .s1 {
    margin-top: 60px;
    h1 {
      padding: 120px 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 99px;
    }
  }

  .s2 {
    .vhot-text {
      width: 60%;
    }

    .vtext {
      font-size: 200px;
      padding-top: 160px;
      color: linear-gradient(to right, #e6005a, #fff);
      /* color: #e6005a; */
    }

    padding: 0px 100px;
    h3 {
      font-size: 30px;
      font-weight: 300;
    }
    h2 {
      font-size: 36px;
      font-weight: 300;
      margin: 60px 0px;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      text-decoration: underline;
      :hover {
        cursor: pointer;
      }
    }
  }

  .video-text {
    width: 60%;
  }

  .s3 {
    background-image: url("./images/7.jpeg");
    background-size: 100%;
    background-attachment: fixed;
    padding-top: 40px;
    padding-bottom: 80px;
    h3 {
      padding: 30px;
      font-size: 60px;
      line-height: 70px;
    }
    p {
      padding: 30px;
      font-size: 36px;
      line-height: 42px;
    }
  }

  .s4 {
    padding: 100px 0px;

    h1 {
      margin-bottom: 80px;
    }

    .block {
      margin: 20px 40px;
      border-radius: 40px;
      /* padding: 20px; */
      width: 300px;
      background-color: #d9d9d9;
      text-align: center;
      img {
        width: 80%;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 33px;
        color: #000000;
        margin-bottom: 30px;
      }
    }
  }
`;

export default Desktop;
