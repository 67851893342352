import { useEffect, useState } from "react";

const EN = {
  s11: "Connect",
  s12: "Share",
  s13: "Engage",

  s21: "Expand your horizons and explore limitless video possibilities",
  s22: "Browse vlogs and interact with friends",
  s23: "Learn more ->",

  s31: "Mini vlogs",
  s32: "connections",
  s33: "and interactions all in one app",
  s34: "Post vlogs and share with your friends",

  s41: "Effortless Connections",
  s42: "Share the Joy.",
  s43: "Capture with Ease.",
  s44: "Chat with friends",
  s45: "Easily manage videos",
  s46: "Record short videos",

  s51: "Embrace the funny moments",
  s52: "Available in the App store and Google play. Create and store memories with",
  s53: "Download now",
};

const CN = {
  s11: "社交",
  s12: "分享",
  s13: "参与",

  s21: "拓展你的视野，探索无限可能的视频",
  s22: "浏览短视频并与朋友进行互动",
  s23: "了解更多 ->",

  s31: "迷你博客",
  s32: "社交",
  s33: "互动, 尽在一个App中",
  s34: "发布微博并与你的朋友分享",

  s41: "愉快社交",
  s42: "分享快乐",
  s43: "轻松捕捉",
  s44: "密友闲聊",
  s45: "视频管理",
  s46: "视频记录",

  s51: "拥抱有趣的瞬间",
  s52: "App store 和 Google play 现已上架. 创造和储存美好记忆，只需要一个",
  s53: "现在就下载吧",
};

const useLang = () => {
  const [lang, setLang] = useState(EN);

  useEffect(() => {
    let str = window.location.search?.split("?lang=")[1];
    if (str?.toUpperCase() === "CN") {
      setLang(CN);
    }
  }, []);

  return { lang, setLang, EN, CN };
};

export default useLang;
