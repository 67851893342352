import React from "react";
import styled from "styled-components";

export default function TermsOfUseEn() {
  return (
    <Wrapper>
      <div className="container">
        <h1>Terms of Service</h1>
        <p>Last version date: June 2023</p>
        <p>
          1) Introduction: This Terms of service is a legal agreement between
          user and Richlink Technologies Inc. for the use of the social media
          app (“Vhot”). By accessing, installing or using the Vhot App, you are
          agreeing to be bound by the terms and conditions of this Terms of
          service. If you do not agree to the terms of this Terms of service, do
          not install or use the App.
          <br /> <br />
          2) License Grant: Richlink Technologies Inc. grants you a
          non-transferable, non-exclusive license to download, install, and use
          the Vhot App on a single mobile device that you own or control, solely
          for your personal use.
          <br /> <br />
          3) Restrictions on Use: You may not copy, distribute, sell or
          otherwise transfer the App or any portion thereof. You may not modify,
          reverse engineer, decompile or create derivative works based on the
          App or any portion thereof. You may not use the Vhot App for any
          illegal or unauthorized purpose.
          <br /> <br />
          4) Intellectual Property Rights: The Vhot App and all intellectual
          property rights related to it, including but not limited to
          trademarks, patents, and copyrights, are owned by Richlink
          Technologies Inc.. You do not acquire any ownership rights in the App
          as a result of using it.
          <br /> <br />
          5) User Content: You are solely responsible for any content,
          information or data that you post, upload, or otherwise make available
          through the Vhot App (“User Content”). You represent and warrant that
          you own or have the necessary rights to use and authorize Richlink
          Technologies Inc. to use all User Content that you post, upload, or
          otherwise make available through the Vhot App. Vhot uses Google Maps
          API.{" "}
          <a
            style={{ color: "#fff" }}
            href="https://policies.google.com/terms?hl=en"
          >
            Google Maps Terms of Service.
          </a>
          <br /> <br />
          6) Disclaimer of Warranties: The Vhot App is provided “as is” without
          warranty of any kind, either express or implied, including but not
          limited to the implied warranties of merchantability and fitness for a
          particular purpose. Richlink Technologies Inc. does not guarantee that
          the Vhot App will meet your requirements or that its operation will be
          uninterrupted or error-free.
          <br /> <br />
          7) Limits of Use:
          <br /> <br />
          The following is a partial list of the kind of content that is
          prohibited in the Service. You may not post, upload, display or
          otherwise make available content that:
          <br />
          <br />
          <ul>
            <li>
              depicts, solicits, glorifies, or encourages child abuse imagery
              including nudity, sexualized minors, or sexual activity with
              minors
            </li>
            <li>
              describes or provides instructional detail on how to perform a
              dangerous activity
            </li>
            <li>
              depicts, promotes, normalizes, or glorifies suicide or self-harm
            </li>
            <li>
              depicts, promotes, or glorifies sexual solicitation, including
              offering or asking for sexual partners, sexual chats or imagery,
              sexual services, premium sexual content, or sexcamming
            </li>
            <li>
              insults another individual, or disparages an individual on the
              basis of attributes such as intellect, appearance, personality
              traits, or hygiene
            </li>
            <li>
              claiming individuals or groups with protected attributes are
              physically, mentally, or morally inferior or referring to them as
              criminals, animals, inanimate objects, or other non-human entities
            </li>
            <li>
              Conditional or aspirational statements that encourage other people
              to commit violence
            </li>
            <li>
              Share instructions on how to artificially increase views, likes,
              followers, shares, or comments
            </li>
            <li>
              promotes acts of physical harm, such as assault or kidnapping
            </li>
            <li>
              Content of humans that depicts: violent or graphic deaths or
              accidents
            </li>
            <li>
              Content of animals that depicts: the slaughter or other
              non-natural death of animals
            </li>
            <li>
              violates or infringes someone else's copyrights, trademarks, or
              other intellectual property rights
            </li>
            <li>
              Attempt to access the Vhot platform in an unauthorized manner, and
              do not create fake or misleading versions of the Vhot platform
            </li>
          </ul>
          For more details, please view our Community Guidelines. If you find
          inappropriate content that violates our Community Guidelines or have
          any other issues you'd like to raise you can send us a report.
          <br />
          <br />
          Vhot takes reasonable measures to expedi,ously remove any infringing
          material we become aware of from our Services. It is Vhot's policy, in
          appropriate circumstances and at its discre,on, to disable or
          terminate the accounts of users of the Services who repeatedly
          infringe copyrights or intellectual property rights of others.
          <br /> <br />
          8) Limitation of Liability: Richlink Technologies Inc. shall not be
          liable for any damages, including but not limited to lost profits or
          data, arising from the use of the Vhot App.
          <br /> <br />
          9) Termination: Richlink Technologies Inc. may terminate this Terms of
          service at any time and for any reason, with or without notice to you.
          Upon termination, you must immediately stop using the App and delete
          it from your mobile device.
          <br /> <br />
          10) Governing Law: This Terms of service shall be governed by and
          construed in accordance with the laws of the state in which Richlink
          Technologies Inc. is located.
          <br /> <br />
          11) Entire Agreement: This Terms of service constitutes the entire
          agreement between you and Richlink Technologies Inc. with respect to
          the use of the Vhot App and supersedes all prior agreements,
          understandings, and negotiations, whether oral or written.
        </p>
        <p>
          Acceptance: By accessing, installing or using the Vhot App, you are
          indicating your acceptance of the terms and conditions of this Terms
          of service.
        </p>
        <p>
          If you have any questions about this Terms of service, please contact
          us at info@richlink.ca
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  padding: 30px;

  h1 {
    font-size: 26px;
    color: #fff;
    padding-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    color: #fff;
    padding: 16px 0px;
  }

  p {
    color: #fff;
    padding: 10px 0px;
  }
`;
